export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '1.1.1',
    name: 'com-mgmclub-mgmlojistas',
    versionDate: '2024-10-06T15:40:40.202Z',
    description: 'App dos Lojistas MGMClub',
    gitCommitHash: 'g2ba06e5',
    gitCommitDate: '2024-10-06T15:40:39.000Z',
    versionLong: '1.1.1-g2ba06e5',
    gitTag: 'v1.1.1',
};
export default versions;
